.read-or-hide {
  color: gray;
  text-decoration: underline gray;
}

.text {
  line-height: 2;
}

.room {
  .wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 68px;
    padding: 96px;
    .room-media {
      flex: 1;
      width: 40%;
      display: flex;
      position: relative;
      justify-content: center;

      .room-media-item {
        img {
          width: 100%;
          height: auto;
        }
      }

      .arrow-left {
        z-index: 2;
        position: absolute;
        width: 2rem;
        height: 2rem;
        left: -4rem;
        top: 16rem;
      }

      .arrow-right {
        z-index: 2;
        position: absolute;
        width: 2rem;
        height: 2rem;
        right: -4rem;
        bottom: 17.5rem;
      }

      .indicators {
        display: flex;
        flex-direction: row;
        position: absolute;
        align-items: center;
        justify-content: center;
        bottom: 1.5rem;
        z-index: 2;

        gap: 5px;

        .indicator {
          height: auto;
          width: 1px;
          border-radius: 100%;
          border: none;
          box-shadow: 0px 0px 5px #525252;
          cursor: pointer;
        }
      }
    }
  }

  .room-details {
    display: flex;
    flex-direction: column;
    width: 60%;

    .room-name {
      color: var(--base-white, #fff);

      font-family: Raleway;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-bottom: 20px;
    }

    .room-details {
      line-height: 8;
      font-size: 12px;
      // padding-inline: 123px;
    }

    .room-description {
      padding-block: 16px;
    }

    .grid-container {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: left;
      align-items: start;
      column-gap: 15px;
      padding: 0;

      .grid-item {
        font-size: 30px;
        text-align: left;
        align-items: left;

        .component {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;

          p {
            font-size: 20px;
          }
        }
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 100px;
      small {
        font-size: 15px;
      }
      .room-action {
        padding-block: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .book {
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 56px;
          font-family: Recoleta;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          padding: 1rem clamp(1rem, 3vw, 3rem);

          small {
            display: block;
            margin-top: 5px;
          }
          /* 125% */
        }

        a {
          flex-direction: row;
          justify-content: space-between;
          display: flex;
        }
      }
    }
  }
}

$breakpoint-tablet: 1100px;
@media (max-width: $breakpoint-tablet) {
  .room {
    .wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 68px;
      padding: 10px;

      .room-media {
        flex: 1;
        flex-shrink: 0;
        width: 100%;

        .room-media-item {
          img {
            height: auto;
          }
        }
      }
    }

    .room-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .room-name {
        color: var(--base-white, #ffffff);

        font-family: Raleway;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-bottom: 20px;
        text-align: center;
      }

      .room-details {
        line-height: 8;
        font-size: 3px;
        text-align: center;
      }

      .room-description {
        padding-block: 16px;
        text-align: center;
      }

      .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: center;
        column-gap: 15px;

        .grid-item {
          font-size: 30px;

          .component {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 12px;

            p {
              font-size: 20px;
            }
          }
        }
      }

      .grid-container {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: center;
        align-items: center;
        column-gap: 5px;

        .grid-item {
          font-size: 30px;
          text-align: center;
          align-items: center;

          .component {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 12px;

            img: {
              width: 100%;
            }

            p {
              font-size: 20px;
            }
          }
        }
      }

      .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        small {
          font-size: 15px;
        }
      }
    }
  }
}
