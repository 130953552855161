.form-container {
  max-width: 900px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;

  label {
    display: block;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }

  input, select, textarea {
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    border: 0;
  }

  h1 {
    text-align: center;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.h-form {
  width: 90%;
  max-width: 800px;
  margin: 0 auto 3rem;

  .button {
    border-radius: 1rem;
  }
}