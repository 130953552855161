.border {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    padding: clamp(10px, 4vw, 78px) 2vw;
    max-width: 1400px;

    .pad {
      padding-bottom: 50px;

      .gold {
        border-radius: 25px;
        color: black;
        padding: 8px 16px;
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: linear-gradient(
            146deg,
            #a27132 0%,
            #f0d063 52.36%,
            #f4ef9c 82.99%,
            #eec54c 88%,
            #93622f 100%
          ),
          #c6ddcd;
      }
    }

    .gold-border {
    }
  }

  .image-set {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;

    .logo {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: clamp(2rem, 6vw, 8rem);
      }
    }

    .splash-photo {
      display: block;
      width: 100%;
      height: 50vh;
      border: 1px solid #f5c986;
      box-sizing: border-box;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1.5px solid #F5C986;
        box-sizing: border-box;
        transform: rotate(-4deg);

        @media (min-width: 1024px) {
          transform: rotate(-2deg);
        }
      }
    }

    .one {
      width: 100%;
      height: 40vh;
      object-fit: cover;
      object-position: center 60%;
      opacity: 0.513;
    }
  }

  .set-2 {
    display: flex;
    flex-direction: row;
    padding: 2vh 5vw;

    img {
      flex: 1;
      width: 40%;
      height: 40%;
    }

    .column {
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 3rem;

      &:only-child {
        max-width: 800px;
      }

      .title-set {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        padding-bottom: 20px;

        .logo {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 50%;
            height: auto;
          }
        }

        .gold-graphic {
          z-index: -5;

          height: 215px;
          width: 440px;
        }
      }

      .title2 {
        width: 320px;
        height: 224px;
        padding-bottom: 40px;
      }

      p {
        font-size: 20px;
        line-height: 2;
        padding-bottom: 50px;
      }

      button {
        width: Hug (485px);
        height: Hug (52px);
        top: 1693px;
        left: 728px;
        padding: 16px 167px 16px 167px;
        border-radius: 56px;
        gap: 8px;
        background-color: #ffffff;
        font-family: Recoleta;
        font-size: 20px;
      }
    }
  }

  .set-3 {
    display: flex;
    flex-direction: row;
    padding: 70px 5vw;

    position: relative;

    .word-set {
      flex: 2;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;

      .b-container {
        padding: 20px;
        padding-bottom: 25px;
        align-items: center;
      }

      .title {
        width: 350px;
        height: 100px;
      }

      p {
        font-size: 18px;
        color: #d0d5dd;
      }
    }

    .column {
      flex: 4.2;
      display: flex;
      flex-direction: column;

      .title2 {
        width: 450px;
        height: 224px;
        padding-bottom: 40px;
      }

      .gold-graphic {
        position: absolute;
        z-index: -5;
        right: 13vw;
        height: 215px;
        width: 440px;
      }

      p {
        font-size: 20px;
        line-height: 2;
      }

      .row {
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;

        button {
          width: 20vw;
          height: 25vh;

          padding: 25px 25px 0 25px;
          border-radius: 14px;

          background: linear-gradient(
              146deg,
              #a27132 0%,
              #f0d063 52.36%,
              #f4ef9c 82.99%,
              #eec54c 88%,
              #93622f 100%
            ),
            #c6ddcd;

          .icon {
            display: flex;

            img {
            }
          }

          .row {
            flex-direction: row;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              font-weight: bold;
              font-size: 24px;
              font-family: Recoleta;
            }

            img {
            }
          }
        }
      }
    }
  }

  .set-4 {
    align-items: center;
    display: flex;
    flex-direction: column;

    .gold {
      width: 18%;
      height: 58%;
      border-radius: 25px;
      color: black;
      padding: 4px 10px;
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      background: linear-gradient(
          146deg,
          #a27132 0%,
          #f0d063 52.36%,
          #f4ef9c 82.99%,
          #eec54c 88%,
          #93622f 100%
        ),
        #c6ddcd;
    }

    img {
      padding: 20px 0 70px 0;
    }
  }

  .h-form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: white;
    padding: 51px 38px 31px 38px;
    border-radius: 12px;

    p {
      display: flex;
      align-items: flex-start;
      color: black;
      font-weight: 550;
    }

    input[type='text'] {
      width: 541px;
      height: 35px;
      border-radius: 10px;
      padding: 8px 12px;
      font-size: 16px;
    }

    input[type='radio'] {
      border-radius: 0;
    }

    .radio-title {
      padding-top: 15px;
      color: black;
    }

    .radio-group {
      align-items: center;
      display: flex;

      input {
        display: none;
      }

      label {
        display: flex;
        justify-content: center;
        padding: 5px 10px;
        cursor: pointer;
        align-items: center;
        color: black;
        font-weight: 600;
      }

      label span {
        position: relative;
        line-height: 22px;
      }

      label span:before,
      label span:after {
        content: '';
      }

      label span:before {
        border: 1px solid #222021;
        background-color: white;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: top;
        border-radius: 6px;
      }

      label span:after {
        align-items: center;
        background: #222021;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 5px;
        left: 5px;
        transition: 300ms;
        opacity: 0;
        border-radius: 4px;
      }

      label input:checked + span:after {
        opacity: 1;
      }
    }

    .b-container {
      padding: 35px 30px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    input[type='submit'] {
      width: Hug (485px);
      height: Hug (52px);
      padding: 16px 167px 16px 167px;
      border-radius: 56px;
      font-weight: bold;
      gap: 8px;
      background-color: #f0d063;
    }
  }
}

$breakpoint-tablet: 1096px;
@media (max-width: $breakpoint-tablet) {
  .border {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;

    .container {
      display: flex;
      flex-direction: column;
      height: auto;
      align-items: center;

      .pad {
        padding-bottom: 50px;

        .gold {
          border-radius: 25px;
          color: black;
          padding: 8px 16px;
          color: #000;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          background: linear-gradient(
              146deg,
              #a27132 0%,
              #f0d063 52.36%,
              #f4ef9c 82.99%,
              #eec54c 88%,
              #93622f 100%
            ),
            #c6ddcd;
        }
      }

      .gold-border {
      }
    }

    .set-2 {
      display: flex;
      flex-direction: column;
      align-items: center;

      .two {
        height: auto;
        width: 60%;
        padding-bottom: 90px;
      }

      .column {
        display: flex;
        align-items: center;
        justify-content: center;
        .title-set {
          .gold-graphic {
            height: auto;
            width: 60%;
          }
        }

        button {
          padding: 16px 100px 16px 100px;

          height: auto;
        }
      }
    }

    .set-3 {
      display: flex;
      flex-direction: column;
      padding-top: 1px;
      align-items: center;
      justify-content: center;

      margin: 0 35vw;
      .word-set {
        .title {
          width: 96%;
        }

        p {
          text-align: center;
        }
      }

      .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 0;

        .row {
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 12px;

          button {
            width: 45%;
          }
        }
      }
    }

    .set-4 {
      img {
        width: 300px;
      }
    }

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      justify-content: center;
      width: 80%;

      form {
        display: flex;
        flex-direction: column;
        align-items: center;

        justify-content: center;
        width: 100%;
        height: auto;
      }

      input[type='text'] {
        width: 190px;
        height: 35px;
        border-radius: 10px;
        padding: 8px 12px;
        font-size: 16px;
      }

      .b-container {
        width: 10%;
        input[type='submit'] {
          align-items: center;
          justify-content: center;
          width: 1200px;
          height: Hug (52px);
          padding: 16px 30px 16px 30px;
          border-radius: 56px;
          font-weight: bold;
          gap: 8px;
          background-color: #f0d063;
        }
      }
    }
  }
}
