@media screen and (max-width: 40em) {
  .responsiveTable td.pivoted {
    overflow: hidden;
  }
}

.responsiveTable td, .responsiveTable th {
  border: 1px solid white !important;
  padding: 0.2em 1em;
}

.responsiveTable {
  border-collapse: collapse;
}

.responsiveTable tr:nth-child(even) {
  background: rgba(255, 255, 255, 0.2);
}

.responsiveTable thead {
  background: rgba(255, 255, 255, 0.3);
}

.invite-form {
  padding: 1rem;
}

.invite-form input {
  border: 1px solid black;
}

.invite-form .PhoneInputInput {
  color: black;
  border: none;
}