
.App {
  padding: 40px;
}
.parent {
    align-items: center;
    display: flex;
    justify-content: center;
 
}
  html {

    overflow-x: hidden;
  }
  
  html body {

    overflow-x: inherit;
  }

  $breakpoint-tablet: 1096px;
@media (max-width: $breakpoint-tablet) {
  .App{
    padding: 2px;
  }
}

.text-center {
  text-align: center;
}

.SignInPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.PhoneInputInput {
  background: transparent;
  color: white;
}

.PhoneInput {
  border: 1px solid white;
  border-image: linear-gradient(146deg, #A27132 0%, #F0D063 52.36%, #F4EF9C 82.99%, #EEC54C 88.00%, #93622F 100%) 1;
  padding: 5px 10px;
}

.button {
  appearance: none;
  background: linear-gradient(146deg, #A27132 0%, #F0D063 52.36%, #F4EF9C 82.99%, #EEC54C 88.00%, #93622F 100%);
  border: none;
  color: black;
  font-weight: bold;
  padding: 10px 20px;
  width: 100%;
  font-size: 1em;
  display: block;
  text-align: center;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}

.button:disabled {
  opacity: 0.5;
}

.button--white {
  background: white;
  color: black;
  width: auto;
  padding: 1rem clamp(1rem, 3vw, 3rem);
  border-radius: calc(1rem + 1em);
}