.prefooter {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.prefooter-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.prefooter-tile {
  border-radius: 8px;
  background: linear-gradient(146deg, #A27132 0%, #F0D063 52.36%, #F4EF9C 82.99%, #EEC54C 88.00%, #93622F 100%);
  padding: 24px;
  color: black;
  font-size: 1.2rem;

  .row {
    display: flex;
    justify-content: space-between;
  }
}