.navContainer {
  display: flex;
  padding: 15px 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 64px;
  border: 1px solid #deded8;
  width: 1200px;

  .nav {
    height: auto;
  }

  .hamburger {
    display: none;
  }

  img {
    width: 102px;
    height: 40px;
    display: block;
  }

  .nav ul {
    align-items: center;
    display: flex;
    list-style: none;
  }

  .nav li {
    margin: 0 10px 0 10px;
  }
}

$breakpoint-tablet: 800px;

@media (max-width: $breakpoint-tablet) {
  .navContainer {
    width: 80%;

    .account {
      display: none;
    }

    .hamburger {
      display: flex;
      z-index: 0;
    }

    img {
      flex: 1;
      justify-content: center;

      align-items: center;
      width: 102px;
      height: 40px;
    }

    .nav ul {
      display: none;
      margin-right: 52px;
    }
  }

  .nav-placement {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
  }
}
