.f-navContainer {
    display: flex;
    padding: 40px 130px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 124px;
    border-radius: 8px;
    background: linear-gradient(152deg, #A27132 0%, #F0D063 52.36%, #F4EF9C 82.99%, #EEC54C 88.00%, #93622F 100%);
    color: black;

    img {
        width: 102px;
        height: 40px;
    }

    .bot-nav ul {
        align-items: center;
        display: flex;
        list-style: none;


    }

    .bot-nav li {
        margin: 0 20px 0 20px;
        font-weight: 600;
        text-decoration: none;
    }




}