.flight-search-result {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 49px 0px rgba(240, 240, 240, 0.08);
  margin: 16px 0;
  color: #0F182E;
  gap: 5%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px 38px;

  .left {
    flex: 3;
    padding-block: 20px;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .segment {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 12px;

      .segment-airline-logo {
        width: 20%;
        height: 50px;
        object-fit: contain;
        object-position: left;
      }

      .flight-search-result-airline {
        text-align: center;
        img {
          height: 15px;
        }

        p {
          font-size: 80%;
        }
      }

      .code {
        font-size: 10px;
        color: #9299a0;
      }
    }

    .times {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 40px;

      .dashed {
        border-top: 1px dashed #c9c9d1;
        flex: 1;
        min-width: 50px;

        .plane {
          position: relative;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          display: inline-flex;
          flex-direction: column;
          align-items: center;

          p {
            font-size: 12px;
            text-align: center;
          }
        }
      }

      .flight-search-result-time {
        text-align: center;
        width: 20%;
        span {
          display: block;
        }
        time {
          font-weight: bold;
          display: block;
          font-size: 20px;
        }
      }
    }

    .destinations {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .flight-search-result-airport {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 12px;
        font-size: 14px;

        p {
          text-align: center;
        }

        .name {
          font-size: 9px;
        }

        .iata {
          border-radius: 21px;
          background: #f3fafe;
          display: inline-flex;
          padding: 2px 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }

  .right {
    border-left: 1px dashed #c9c9d1;
    height: auto;
    padding: 0 5%;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 60px;

      .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .vertical {
      border-left: 1px dashed #c9c9d1;
      height: 65px;
    }

    .type {
    }

    .price {
      font-weight: bold;
      font-size: 25px;
    }
  }
}

$breakpoint-tablet: 910px;
@media (max-width: $breakpoint-tablet) {
  .flight-search-result {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    height: auto;
    padding: 0 5%;

    .left {
      align-items: center;
      display: flex;
      flex: 3;
      padding-block: 20px;
      flex-direction: column;
      justify-content: center;

      gap: 10px;
      .segment {
        gap: 30px;

        .flight-search-result-airline {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 5px;

          img {
            height: 15px;
          }

          p {
            font-size: 10px;
          }
        }

        .code {
          display: none;
        }
      }

      .times {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
        .flight-search-result-time {
          font-size: 80%;
        }

        .dashed {
          position: relative;
          .plane {
            right: 0vw;
          }
        }
      }

      .destinations {
        width: 100%;
        .flight-search-result-airport {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;
          font-size: 14px;
          width: 25%;

          p {
            display: none;
          }
        }
      }
    }

    .right {
      border: none;
      .row {
        .column {
          .type {
            margin: 0;
          }
        }
      }

      .price {
        position: relative;
        font-weight: bold;
        font-size: 18px;
        margin: 0 0 10px 0;
      }

      img {
        display: none;
      }
    }
  }
}
