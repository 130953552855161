@import url(https://fonts.googleapis.com/css?family=Ek+Mukta:200);

.window {
  z-index: 5;
  background-color: white;
  color: black;
  width: 100vw;
  height: 100vh;

  .header {
    padding: 30px;

    .back {
      position: absolute;
      top: 2rem;
      right: 2rem;
    }

    .row {
      justify-content: center;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    .menu {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 60px;
      justify-content: space-between;
      padding-block: 40px;
      padding-right: 25px;
      list-style-type: none;

      li {
        font-size: 23px;
        font-weight: 500;
        transition: background-color 0.5s ease;
      }
    }
  }
}
