.itinerary {
  max-width: 1600px;
  margin: 0 auto;
  flex-direction: column;
}

.itinerary-day {
  margin: 2rem 0;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 2rem;
  }

  &.alt-photo {
    grid-template-columns: minmax(auto, 65%) 1fr;
    .itinerary-description {
      @media (min-width: 768px) {
        order: 2;
      }
    }
  }

  img {
    max-width: 100%;
    max-height: 75vh;
  }
}

.itinerary-description {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, auto);
  grid-gap: 0.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;
}

.title,
.gold-pill,
.description {
  grid-column: 1 / -1;
  margin: 0;
}

.title {
  font-size: clamp(2rem, 6vw, 6rem);
  font-weight: bold;
  text-align: center;
}

.gold-pill {
  margin: 0.5rem auto;
  color: black;
  white-space: nowrap;
  font-family: Recoleta;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 0.5em 2em;
  border-radius: 35px;
  background: linear-gradient(
      146deg,
      #a27132 0%,
      #f0d063 52.36%,
      #f4ef9c 82.99%,
      #eec54c 88%,
      #93622f 100%
    ),
    #c6ddcd;
}

@media (min-width: 768px) {
  .itinerary-description {
    grid-template-columns: 30% 1fr;
  }

  .date,
  .time,
  .gold-pill {
    grid-column: 1;
  }

  .date, .gold-pill {
    align-self: last baseline;
  }

  .time {
    align-self: first baseline;
  }

  .date,
  .time {
    margin: 0;
  }

  .description {
    grid-column: 2;
    grid-row: 3 / -1;
    align-self: center;
    text-align: left;
    font-size: 1.2rem;
  }

  .gold-pill {
    margin: 0 auto 0 0;

    &.first {
      margin: 3rem auto 0 auto;
      width: auto;
    }
  }

  .title {
    text-align: left;
  }
}
