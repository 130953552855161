.flight-list {
  max-width: 1000px;
  margin: 0 auto;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;

  .theFlex {
    flex: 1;

    .titleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      gap: 164px;
      max-width: 1000px;
      margin: 0 auto;

      h1 {
        font-size: clamp(2rem, 5vw, 6rem);
        flex: 1;
      }

      p {
        flex: 3;
        font-size: 15.5px;
      }
    }

    .background {
      padding-top: 17px;
      width: 100%;
      padding-bottom: 90px;
    }

    .fancy {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      position: relative;

      p {
        color: var(--base-white, #fff);
        text-align: center;
        font-family: Recoleta;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: #030b11;
        padding: 0 1rem;
        margin: 0;
      }

      .line {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0;
        z-index: -1;
      }
    }

    .flights {
      .return-title {
        font-size: 20px;
      }

      .selected {
        .flight-search-result {
          background-image: linear-gradient(white, white),
            linear-gradient(
              146deg,
              #a27132 0%,
              #f0d063 52.36%,
              #f4ef9c 82.99%,
              #eec54c 88%,
              #93622f 100%
            );
          border: double 10px transparent;
          background-origin: border-box;
          background-clip: padding-box, border-box;
          border-radius: 20px;
        }
      }

      .center {
        display: flex;
        flex: 1;
        align-items: center;
        font-size: 30px;
        flex-direction: column;
      }
    }

    .button-container {
      padding-block: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      .bookNow {
        text-decoration: none;
        display: flex;
        padding: 12px 97px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 16px;
        color: black;
        font-family: Recoleta;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        background-color: none;
        /* 125% */
      }
    }

    .theForm {
      max-width: 900px;
      flex-wrap: wrap;
      margin: 0 auto;
      padding: 2rem;
      border-radius: 12px;
      background: linear-gradient(
          146deg,
          #a27132 0%,
          #f0d063 52.36%,
          #f4ef9c 82.99%,
          #eec54c 88%,
          #93622f 100%
        ),
        #c6ddcd;

      .contents {
        display: flex;

        align-items: center;
        justify-content: center;
        flex-direction: column;

        .row {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          gap: 28px;

          .column {
            display: flex;
            flex-direction: column;

            .request {
              display: flex;
              padding: 12px 12px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 48px;
              border: 1px solid var(--gray-300, #d0d5dd);
              background: var(--base-white, #fff);
              width: 100%;
              /* Shadow/xs */
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }

            .request2 {
              appearance: none;
              display: flex;
              padding: 12px 12px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 48px;
              border: 1px solid var(--gray-300, #d0d5dd);
              background-color: var(--base-white, #fff);
              width: 100%;
              /* Shadow/xs */
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }

            label {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              color: #030b11;
            }
          }
        }

        .search {
          width: 1110px;
          height: Hug (52px);

          padding: 5px 0 5px 0;
          border-radius: 56px;
          gap: 8px;
          background-color: #ffffff;
          font-family: Recoleta;
          font-size: 20px;
        }
      }

      .f-radio-group {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        padding-bottom: 30px;

        p {
          font-weight: bold;
          color: black;
        }

        input {
          display: none;
        }

        label {
          display: flex;
          justify-content: center;
          padding: 5px 10px;
          cursor: pointer;
          align-items: center;
          color: black;
          font-weight: 600;
        }

        label span {
          position: relative;
        }

        label span:before,
        label span:after {
          content: '';
        }

        label span:before {
          border: 1px solid #222021;

          width: 20px;
          height: 20px;
          margin-right: 10px;
          display: inline-block;
          vertical-align: top;
          border-radius: 20px;
        }

        label span:after {
          align-items: center;
          background: #222021;
          width: 12px;
          height: 12px;
          position: absolute;
          top: 5px;
          left: 5px;
          transition: 300ms;
          opacity: 0;
          border-radius: 17px;
        }

        label input:checked + span:after {
          opacity: 1;
        }
      }
    }

    .footer-set {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      p {
        font-size: 26px;
      }

      ul p {
        font-size: 18px;
      }
    }
  }
}

$breakpoint-tablet: 1240px;
@media (max-width: $breakpoint-tablet) {
  .page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .theFlex {
      flex: 1;
      width: 90%;

      .titleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;
        text-align: center;
        padding: 0 10px;

        img {
          flex: 1;
        }

        p {
          flex: 3;

          font-size: 15.5px;
        }
      }

      .fancy {
        padding-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        position: relative;

        p {
          color: var(--base-white, #fff);
          text-align: center;
          font-family: Recoleta;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0 1rem;
        }

        .line {
          border-top: 1px solid #cdcdcd;
          width: 120%;
        }

        .sticker-1 {
          display: none;
        }

        .sticker-2 {
          display: none;
        }
      }

      .background {
        width: 100%;
        padding-top: 17px;
        padding-bottom: 0;
      }

      .theForm {
        height: auto;

        .contents {
          .row {
            grid-template-columns: 1fr 1fr;
            width: 100%;

            .column {
              .request {
              }
            }
          }
        }
      }

      .flights {
        .center {
          text-align: center;
        }

        .selected {
          padding: 0;
        }

        .dropdown {
          // width: 110%;
        }
      }
    }
  }
}

.button-container {
  gap: 1rem;
  flex-direction: column;
}

.total-price-label {
  text-align: center;
  font-size: 1.2rem;
  margin: 0;
}

.total-price {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

@media (min-width: 800px) {
  .total-price-label {
    text-align: right;
  }

  .button-container {
    flex-direction: row;
  }
}