@font-face {
  font-family: 'Recoleta';
  src: url('../public/Recoleta-Thin.woff2') format('woff2'),
    url('../public/Recoleta-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('../public/Recoleta-Light.woff2') format('woff2'),
    url('../public/Recoleta-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('../public/Recoleta-Regular.woff2') format('woff2'),
    url('../public/Recoleta-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('../public/Recoleta-Medium.woff2') format('woff2'),
    url('../public/Recoleta-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('../public/Recoleta-SemiBold.woff2') format('woff2'),
    url('../public/Recoleta-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('../public/Recoleta-Bold.woff2') format('woff2'),
    url('../public/Recoleta-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('../public/Recoleta-Black.woff2') format('woff2'),
    url('../public/Recoleta-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Snell Roundhand';
  src: url('../public/SnellRoundhand-BoldScript.woff2') format('woff2'),
    url('../public/SnellRoundhand-BoldScript.woff') format('woff');
  font-weight: 700;
  font-style: oblique;
}

@font-face {
  font-family: 'Snell Roundhand';
  src: url('../public/SnellRoundhand-BlackScript.woff2') format('woff2'),
    url('../public/SnellRoundhand-BlackScript.woff') format('woff');
  font-weight: 900;
  font-style: oblique;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #030B11;
  color: #EAECF0;
  font-family: Recoleta, serif;
  font-variant-ligatures: no-common-ligatures;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;


  flex-direction: column;
}

h1,
h2,
h3 {
  font-family: Snell Roundhand, cursive;
  font-weight: 700;
  font-style: oblique;
}

a {
  color: inherit;
}

* {
  box-sizing: border-box;
}

.select {
  --image-source: url('/public/arrow-down.svg');
  background-image: var(--image-source);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
  background-color: white;
  appearance: none;
  width: 100%;
}

input,
select,
button,
textarea {
  font-family: inherit;
  font-size: 16px;
  border: 0;
}

input:not([type='checkbox']):not([type='radio']), textarea {
  width: 100%;
}

.react-datepicker {
  font-family: inherit !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: black;
  background: linear-gradient(146deg,
      #a27132 0%,
      #f0d063 52.36%,
      #f4ef9c 82.99%,
      #eec54c 88%,
      #93622f 100%),
    #c6ddcd;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background: #f4ef9c !important;
}

.EnterCode {
  padding: 0.5rem 1rem;
  font-size: 16px;
  border-radius: 0.5rem;
}