.hero {
  .content {
    align-items: center;
    display: flex;
    flex-direction: column;

    .title-component {
      padding-top: 30px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;

      .logo {
        position: absolute;

        display: flex;
        justify-content: center;
        align-items: center;

        .title {
          font-size: 140px;
          text-shadow: 2px 4px 4px #000000;
        }
      }

      .background {
        height: 50vh;
        object-fit: cover;
        width: 100%;
      }
    }

    .gold {
      border-radius: 25px;
      color: black;
      padding: 8px 16px;
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background: linear-gradient(
          146deg,
          #a27132 0%,
          #f0d063 52.36%,
          #f4ef9c 82.99%,
          #eec54c 88%,
          #93622f 100%
        ),
        #c6ddcd;

      &.first {
        margin: 3rem auto 0;
      }
    }

    .row {
      display: flex;
      flex-direction: row;

      align-items: flex-start;
      padding-block: 120px;
      padding-inline: 2vw;

      .title {
        font-size: 90px;
        flex: 2;
      }

      .column {
        flex: 3;

        p {
          font-size: 15px;
        }
      }
    }
  }

  .set-3 {
    display: flex;
    flex-direction: row;
    padding: 170px 5vw;

    position: relative;

    .word-set {
      flex: 2;

      .b-container {
        padding: 20px;
        padding-bottom: 25px;
        align-items: center;
      }

      .title {
        width: 350px;
        height: 100px;
      }

      p {
        font-size: 18px;
        color: #d0d5dd;
      }
    }

    .column {
      flex: 4.2;
      display: flex;
      flex-direction: column;
      margin: 0 10px 0 55px;

      .title2 {
        width: 450px;
        height: 224px;
        padding-bottom: 40px;
      }

      .gold-graphic {
        position: absolute;
        z-index: -5;
        right: 13vw;
        height: 215px;
        width: 440px;
      }

      p {
        font-size: 20px;
        line-height: 2;
      }

      .row {
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;

        button {
          width: 389px;
          height: 202px;

          padding: 25px 25px 0 25px;
          border-radius: 14px;

          background: linear-gradient(
              146deg,
              #a27132 0%,
              #f0d063 52.36%,
              #f4ef9c 82.99%,
              #eec54c 88%,
              #93622f 100%
            ),
            #c6ddcd;

          .icon {
            display: flex;

            img {
            }
          }

          .row {
            flex-direction: row;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              font-weight: 500;
              font-size: 24px;
              font-family: Recoleta;
            }

            img {
            }
          }
        }
      }
    }
  }

  .set-4 {
    align-items: center;
    display: flex;
    flex-direction: column;

    .gold {
      width: 18%;
      height: 58%;
      border-radius: 25px;
      color: black;
      padding: 4px 10px;
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      background: linear-gradient(
          146deg,
          #a27132 0%,
          #f0d063 52.36%,
          #f4ef9c 82.99%,
          #eec54c 88%,
          #93622f 100%
        ),
        #c6ddcd;
    }

    img {
      padding: 20px 0 70px 0;
    }
  }

  .h-form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: white;
    padding: 51px 38px 51px 38px;
    border-radius: 12px;

    p {
      display: flex;
      align-items: flex-start;
      color: black;
      font-weight: 550;
    }

    input[type='text'] {
      width: 541px;
      height: 35px;
      border-radius: 10px;
      padding: 8px 12px;
      font-size: 16px;
    }

    input[type='radio'] {
      border-radius: 0;
    }

    .radio-title {
      padding-top: 15px;
      color: black;
    }

    .radio-group {
      align-items: center;
      display: flex;

      input {
        display: none;
      }

      label {
        display: flex;
        justify-content: center;
        padding: 5px 10px;
        cursor: pointer;
        align-items: center;
        color: black;
        font-weight: 600;
      }

      label span {
        position: relative;
        line-height: 22px;
      }

      label span:before,
      label span:after {
        content: '';
      }

      label span:before {
        border: 1px solid #222021;
        background-color: white;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: top;
        border-radius: 6px;
      }

      label span:after {
        align-items: center;
        background: #222021;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 5px;
        left: 5px;
        transition: 300ms;
        opacity: 0;
        border-radius: 4px;
      }

      label input:checked + span:after {
        opacity: 1;
      }
    }

    .b-container {
      padding: 35px 30px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    input[type='submit'] {
      width: Hug (485px);
      height: Hug (52px);
      padding: 16px 167px 16px 167px;
      border-radius: 56px;
      font-weight: bold;
      gap: 8px;
      background-color: #f0d063;
    }
  }
}

$breakpoint-tablet: 1100px;
@media (max-width: $breakpoint-tablet) {
  .hero {
    .content {
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .title-component {
        padding-top: 30px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;

        .logo {
          position: absolute;

          display: flex;
          justify-content: center;
          align-items: center;

          .title {
            font-size: 40px;
            text-shadow: 2px 4px 4px #000000;
          }
        }

        .background {
          height: 50vh;
          object-fit: cover;
          width: 100%;
        }
      }

      .gold {
        border-radius: 25px;
        color: black;
        padding: 8px 16px;
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: linear-gradient(
            146deg,
            #a27132 0%,
            #f0d063 52.36%,
            #f4ef9c 82.99%,
            #eec54c 88%,
            #93622f 100%
          ),
          #c6ddcd;
      }

      .row {
        display: flex;
        flex-direction: column;

        align-items: flex-start;

        .title {
          font-size: 20px;

          padding-top: -39px;
        }

        .column {
          p {
            font-size: 15px;
            text-align: center;
          }
        }
      }
    }

    .set-3 {
      display: flex;
      flex-direction: column;
      padding-top: 1px;
      align-items: center;
      justify-content: center;

      .word-set {
        .title {
          width: 96%;
        }

        p {
          text-align: center;
        }
      }

      .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 0;

        .row {
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 12px;

          button {
            width: 45%;

            p {
              text-decoration: none;
            }
          }
        }
      }
    }

    .set-4 {
      align-items: center;
      display: flex;
      flex-direction: column;

      .gold {
        width: 18%;
        height: 58%;
        border-radius: 25px;
        color: black;
        padding: 4px 10px;
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        background: linear-gradient(
            146deg,
            #a27132 0%,
            #f0d063 52.36%,
            #f4ef9c 82.99%,
            #eec54c 88%,
            #93622f 100%
          ),
          #c6ddcd;
      }

      img {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      justify-content: center;

      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%;
        justify-content: center;
      }

      input[type='text'] {
        width: 190px;
        height: 35px;
        border-radius: 10px;
        padding: 8px 12px;
        font-size: 16px;
      }

      .b-container {
        width: 10%;
        input[type='submit'] {
          align-items: center;
          justify-content: center;
          width: 1200px;
          height: Hug (52px);
          padding: 16px 30px 16px 30px;
          border-radius: 56px;
          font-weight: bold;
          gap: 8px;
          background-color: #f0d063;
        }
      }
    }
  }
}

.dialog__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease-in-out;
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: auto;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background: none;
}

.dialog[open] {
  opacity: 1;
  z-index: 1000;
}

.dialog[open] .dialog__overlay {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
}

.dialog__content {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1001;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 1rem 1.5rem;
  width: 100%;
  max-width: 500px;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.dialog[open] .dialog__content {
  opacity: 1;
  z-index: 1001;
  transform: translate(-50%, -50%) scale(1);
}

.button--close {
  appearance: none;
  background: black;
  color: white;
  border-radius: 4px;
  padding: 0.5rem;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  font-size: 1.3rem;
  line-height: 1.3rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.dialog__h3 {
  font-size: 2rem;
  line-height: 2rem;
  margin: 1rem 0 1rem 0;
  font-family: inherit;
  font-style: normal;
}
